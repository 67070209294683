/**
 *  Student store
 */
import Vue        from 'vue';
import { config } from '../../config/spa';
import { omit }   from 'lodash-es';

export default {

    namespaced: true,

    // Store state
    state: {

        total: null

    },

    // Generate getters
    getters: {

        total: state => state.total

    },

    // Setters
    mutations: {

        // Auth - Success
        setTotal ( state, total ) {

            state.total = total;

        }

    },

    // Actions
    actions: {

        // Count total students
        async total ( context ) {

            // If we have a total, use that, otherwise go fetch
            if ( context.state.total !== null ) {
                return context.state.total;
            }

            try {

                const response = await this._vm.$http.get (
                    `${ config.apiRoot }/students?offset=0`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                context.commit ( 'setTotal', response.data.total );
                return response.data.total;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        },

        // Save changes
        async save ( context, record ) {

            try {

                let response;

                // Convert new identifier to integer, otherwise remove it
                if ( !record.id ) {
                    record.identifier = parseInt ( record.identifier, 10 );
                } else {
                    delete record.identifier;
                }

                if ( record.id ) {
                    response = await this._vm.$http.put (
                        `${ config.apiRoot }/student/${ record.id }`,
                        omit ( record, [ 'id' ] ),
                        {
                            responseType   : 'json',
                            withCredentials: false // CORS dev server
                        }
                    );
                } else {
                    response = await this._vm.$http.post (
                        `${ config.apiRoot }/students`,
                        record,
                        {
                            responseType   : 'json',
                            withCredentials: false // CORS dev server
                        }
                    );
                }

                return response;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        },

        // Delete a record
        async delete ( context, id ) {

            try {

                let response;

                response = await this._vm.$http.delete (
                    `${ config.apiRoot }/student/${ id }`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                return response;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        },

        // Next identifier
        async nextIdentifier () {

            try {

                let response;

                response = await this._vm.$http.get (
                    `${ config.apiRoot }/students/identifier/next`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                return response.data.identifier;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        }

    }

};