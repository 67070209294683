import { config } from '../../config/spa';

export default {

    computed: {

        isProduction () {
            return process.env.NODE_ENV === 'production';
        },

        config () {
            return config;
        },

        isLoggedIn () {
            return this.$store.getters[ 'user/isLoggedIn' ];
        },

        isAdmin () {
            return this.$store.getters[ 'user/isAdmin' ];
        },

        user () {
            return this.$store.getters[ 'user/profile' ];
        }

    }

};