import Vue   from 'vue';
import axios from 'axios';

// The plugin
const HttpPlugin = {

    // Plugin installation
    install ( Vue ) {

        // Global headers
        axios.defaults.headers.common[ 'Accept' ] = 'application/json';
        axios.defaults.headers.common[ 'Content-Type' ] = 'application/json';
        axios.defaults.timeout = 30000;
        axios.defaults.validateStatus = function ( status ) {
            return status >= 200 && status < 400;
        };

        // Non-auth instance
        Object.defineProperty (
            Vue.prototype,
            '$httpAnon',
            { value: axios.create () }
        );

        // Authenticated instance
        const axiosInstance = axios.create();

        // Auth expiration interceptor
        axiosInstance.interceptors.response.use ( null, e => {

            // Handle unauth server responses
            if ( e.response.status === 401 ) {

                Vue.prototype.$utils.logger (
                    'info',
                    '[AUTH] 401 Auth expiration intercepted.'
                );
                Vue.prototype.$eventBus.$emit ( 'auth-logged-out' );

            }

            return Promise.reject(e);

        } );

        Object.defineProperty ( Vue.prototype, '$http', { value: axiosInstance } );

    }

};

Vue.use ( HttpPlugin );