let config;

if ( process.env.NODE_ENV === 'production' ) {
    config = {
        environment: 'production',
        apiRoot    : '/api/stable'
    };
} else {
    config = {
        environment: 'development',
        apiRoot    : 'http://localhost:5050/api/stable'
    };
}

export { config };