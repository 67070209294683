<template>
	<div v-show="visible" class="alert alert-danger alert-dismissible fade show" role="alert">
		{{ error.message }}
		<button type="button" class="close" @click="clear">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
</template>

<script>

export default {

    name      : 'ErrorAlert',
    mixins    : [],
    components: {},

    props: {

        error: {
            type: [Error, Boolean]
        },

        variant: {}

    },

    watch: {
        error: function ( newErr ) {
            if ( newErr ) {
                this.visible = true;
            }
        }
    },

    data () {
        return {

            visible: false

        };
    },

    methods: {

        clear () {

            this.visible = false;
            this.$emit ( 'alert-closed' );

        }

    }

};

</script>

<style scoped lang="scss">

</style>