import Vue  from 'vue';
import Vuex from 'vuex';

Vue.use ( Vuex );

import userModule            from './user';
import locationModule        from './location';
import classModule           from './class';
import transactionTypeModule from './transaction-type';
import paymentMethodModule   from './payment-method';
import studentModule         from './student';
import paymentModule         from './payment';
import paymentImportModule   from './payment-import';

export default new Vuex.Store (
    {
        modules: {
            user                  : userModule,
            location              : locationModule,
            [ 'transaction-type' ]: transactionTypeModule,
            [ 'payment-method' ]  : paymentMethodModule,
            class                 : classModule,
            student               : studentModule,
            payment               : paymentModule,
            [ 'payment-import' ]  : paymentImportModule
        }
    }
);
