/**
 * Wrapper for sweetalert2 custom alerts
 */

import Vue       from 'vue';
import Swal      from 'sweetalert2';
import { merge } from 'lodash-es';
import store     from '../store';
import router    from '../router';

// Default options - required for theme compatibility
// https://sweetalert2.github.io/#configuration
const defaultOptions = {

    // Base Style
    //background    : '#000000',
    backdrop      : 'rgba(0, 0, 0, 0.3)',
    buttonsStyling: false,
    padding       : '3rem 3rem 2rem',
    customClass   : {
        confirmButton: 'btn btn-primary',
        cancelButton : 'btn btn-link',
        popup        : 'p-4'
    },

    // Labels
    title: 'Alert',
    html : '',
    icon : 'info',

    // Buttons
    showCloseButton  : false,
    showConfirmButton: true,
    showCancelButton : false,
    confirmButtonText: 'OK',
    cancelButtonText : 'Cancel',
    reverseButtons   : true

};

// The plugin
const libAlert = {

    // Simple alert
    alert: async options => {

        return await Swal.fire ( merge (
            {},
            defaultOptions,
            {
                allowOutsideClick: false
            },
            options
        ) );

    },

    // Confirmation
    // https://sweetalert2.github.io/#handling-dismissals
    confirm: async options => {

        return await Swal.fire ( merge (
            {},
            defaultOptions,
            {
                icon             : 'question',
                showCancelButton : true,
                allowOutsideClick: false
            },
            options
        ) );

    },

    // Error
    error: async options => {

        return await Swal.fire ( merge (
            {},
            defaultOptions,
            {
                icon             : 'error',
                allowOutsideClick: false
            },
            options
        ) );

    },

    // Capture text input
    inputText: async options => {

        return await Swal.fire ( merge (
            {},
            defaultOptions,
            {
                icon: null
            },
            options,
            {
                input           : 'text',
                inputValidator  : ( value ) => {
                    if ( !value ) {
                        return 'Please enter a value.';
                    }
                },
                showCancelButton: true
            }
        ) );

    },

    // Display a vue component with bindings and return the props
    // Component name must be globally registered
    alertVue: async ( options, componentName, propsData, onConfirm ) => {

        const ComponentClass = Vue.extend ( Vue.component ( componentName ) );
        const instance = new ComponentClass (
            {
                store,
                router,
                propsData: propsData
            }
        );

        const result = await Swal.fire ( merge (
            {},
            defaultOptions,
            {
                icon             : null,
                allowOutsideClick: false
            },
            options,
            {
                html      : '<div id="alertVue"></div>',
                willOpen  : () => {
                    instance.$mount ();
                    document
                        .getElementById ( 'alertVue' )
                        .appendChild ( instance.$el )
                    ;
                },
                preConfirm: async () => {
                    if ( onConfirm ) {
                        return await onConfirm ( instance );
                    } else {
                        return instance;
                    }
                }
            }
        ) );

        // Destroy our dynamic instance
        // Necessary? Not sure..
        instance.$destroy ();

        return result;

    },

    // Display an HTML form with submit bindings and return the form data
    // <form> must be the root component
    alertForm: async ( options, formHtml, formClasses = '' ) => {

        // Add the form class
        // and submit handler in case it contains a submit button/control
        formHtml = formHtml.replace ( '<form', `<form @submit.prevent class="${ formClasses }"` );

        // Create the dynamic wrapper component
        const FormComponent = Vue.extend (
            {
                name    : 'alertForm',
                template: formHtml,
                data () {
                    return {
                        form: null
                    };
                },
                methods : {
                    getFormData () {
                        const formData = new FormData ( this.form );
                        return Object.fromEntries ( formData );
                    }
                }
            }
        );

        // Instantiate it
        const instance = new FormComponent ();

        // Create the alert
        const result = await Swal.fire ( merge (
            {},
            defaultOptions,
            {
                icon             : null,
                allowOutsideClick: false
            },
            options,
            {
                html        : '<div id="alertForm"></div>',
                onBeforeOpen: () => {

                    // Mount our component
                    instance.$mount ();
                    const wrapper = document.getElementById ( 'alertForm' );
                    wrapper.appendChild ( instance.$el );

                    // Keep a form reference
                    instance.form = wrapper.firstChild;

                },
                preConfirm  : () => instance.getFormData ()
            }
        ) );

        // Destroy our dynamic instance
        // Necessary? Not sure..
        instance.$destroy ();

        return result;

    }

};

const Plugin = {

    name: 'AlertPlugin',

    install ( Vue ) {

        // Create instance methods
        Vue.prototype.$alert = libAlert.alert;
        Vue.prototype.$confirm = libAlert.confirm;
        Vue.prototype.$error = libAlert.error;
        Vue.prototype.$inputText = libAlert.inputText;
        Vue.prototype.$alertVue = libAlert.alertVue;
        Vue.prototype.$alertForm = libAlert.alertForm;

    }

};

Vue.use ( Plugin );