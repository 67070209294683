/**
 *  Transaction type store
 */
import Vue        from 'vue';
import { config } from '../../config/spa';
import { omit }   from 'lodash-es';

export default {

    namespaced: true,

    // Actions
    actions: {

        // Save changes
        async save ( context, record ) {

            try {

                let response;

                if ( record.id ) {
                    response = await this._vm.$http.put (
                        `${ config.apiRoot }/transaction-type/${ record.id }`,
                        omit ( record, [ 'id' ] ),
                        {
                            responseType   : 'json',
                            withCredentials: false // CORS dev server
                        }
                    );
                } else {
                    response = await this._vm.$http.post (
                        `${ config.apiRoot }/transaction-types`,
                        record,
                        {
                            responseType   : 'json',
                            withCredentials: false // CORS dev server
                        }
                    );
                }

                return response;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        },

        // Delete a record
        async delete ( context, id ) {

            try {

                let response;

                response = await this._vm.$http.delete (
                    `${ config.apiRoot }/transaction-type/${ id }`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                return response;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        }

    }

};