import Vue            from 'vue';
import _              from 'lodash-es';
import { v4 as UUID } from 'uuid';
import saveAs         from 'file-saver';
import PapaCSV        from 'papaparse';

// Custom error contructor
class AppError extends Error {

    constructor ( data = {}, ...params ) {

        // Pass remaining arguments (including vendor specific ones) to parent
        // constructor
        super ( ...params );

        // Maintains proper stack trace for where our error was thrown (only
        // available on V8)
        if ( Error.captureStackTrace ) {
            Error.captureStackTrace ( this, AppError );
        }

        this.name = data.name || 'AppError';

        // Custom debugging information
        Object
            .keys ( data )
            .forEach ( key => this[ key ] = data[ key ] )
        ;

        // Brace message
        this.message = data.message || 'Unknown error';

        // Validation errors?
        if ( Object.prototype.hasOwnProperty.call ( data, 'errors' ) && data.errors instanceof Array ) {
            this.errors = data.errors;
        }

        // Date
        this.createdAt = new Date ();

    }

}

const auditFields = [
    'createdAt', 'createdBy', 'updatedAt', 'updatedBy'
];

// The service
const Utils = {

    auditFields: auditFields,

    // Get dom element by id
    el: id => document.getElementById ( id ),

    // Winston-style logger function
    logger: ( type, message, data ) => {

        // Map console methods
        let method = 'log';
        switch ( type ) {
            case 'error':
            case 'warn':
            case 'debug':
                method = type;
                break;
        }

        // Output
        /* eslint-disable no-console */
        if ( type === 'error' ) {
            if ( data.stack ) {
                // eslint-disable-next-line no-console
                console.error ( data.stack );
            }
            // eslint-disable-next-line no-console
            console.error ( message, { ...data } );
        } else if ( data ) {
            // eslint-disable-next-line no-console
            console[ method ] ( message, data );
        } else {
            // eslint-disable-next-line no-console
            console[ method ] ( message );
        }
        /* eslint-enable no-console */

    },

    // Enhanced encodeURIComponent - converts single apostrophes
    encodeURIComponentProperly: str => {
        return encodeURIComponent ( ( '' + str ).replace ( /&#39;/g, '\'' ) )
            .replace ( /'/g, '%27' );
    },

    // Attempt to format a components name
    formatComponentName ( vm, additionalInfo = false ) {
        if ( vm.$root === vm ) {
            return 'ROOT';
        }
        const name = vm._isVue ? vm.$options.name || vm.$options._componentTag : vm.name;

        let ret = ( name ? name : 'ANONYMOUS' );
        if ( additionalInfo ) {
            ret += ( vm._isVue && vm.$options.__file ? ' (' + vm.$options.__file + ')' : '' );
        }
        return ret;
    },

    // Normalise error object
    normaliseError ( e ) {

        let err = {};

        if ( typeof e === 'string' ) {
            err = new AppError ( { message: e } );
        } else if ( e.name === 'AppError' ) {
            return e;
        } else if ( e.isAxiosError ) {

            if ( e.response ) {

                // Response error
                err = new AppError (
                    {
                        name   : 'ResourceResponseError',
                        url    : e.response.config.url,
                        message: e.response.data && e.response.data.message ? e.response.data.message : ( e.response.data.toString () || e.response.statusText ),
                        status : e.response.data && e.response.data.status ? e.response.data.status : e.response.status,
                        errors : e.response.data && e.response.data.errors ? e.response.data.errors : undefined,
                        headers: e.response.headers ? e.response.headers : null
                    }
                );

            } else if ( e.request ) {

                // Request error
                err = new AppError (
                    {
                        name   : 'ResourceRequestError',
                        url    : e.config.url,
                        message: e.message || e.request.statusText,
                        status : e.request.status,
                        headers: e.config.headers ? e.config.headers : null
                    }
                );

            } else {

                // Treat as std error
                err = new AppError ( e );

            }

        } else {

            // Convert to std object
            err = new AppError ( e );

        }

        // Brace message
        err.message = err.message || err.toString ();

        return err;

    },

    // Generate a random string for an autocomplete field
    randomAutoComplete () {
        return Math
            .random ()
            .toString ( 36 )
            .substring ( 2, 15 ) +
            Math
                .random ()
                .toString ( 36 )
                .substring ( 2, 15 );
    },

    // Generate a random string
    // TODO use chance library
    createRandomString ( length ) {
        const chars = 'abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890'.split (
            '' );
        let result = '';
        for ( let i = length; i > 0; --i ) {
            result += chars[ Math.floor ( Math.random () * chars.length ) ];
        }
        return result;
    },

    // Generate a random date
    createRandomDate ( start, end ) {
        return new Date ( start.getTime () + Math.random () * ( end.getTime () - start.getTime () ) );
    },

    // Create random integer
    createRandomInteger ( from, to ) {
        return Math.floor ( Math.random () * ( to - from + 1 ) + from );
    },

    // Create random decimal
    createRandomDecimal ( from, to, fractionDigits = 2 ) {
        return parseFloat ( ( Math.random () * ( to - from + 1 ) + from ).toFixed (
            fractionDigits ) );
    },

    // Generate a v4 uuid
    uuid () {
        return UUID ();
    },

    // Add dynamic page script
    loadPageScript ( src ) {
        const script = document.createElement ( 'script' );
        script.setAttribute ( 'crossorigin', 'anonymous' );

        document.getElementsByTagName ( 'head' )[ 0 ].appendChild ( script );
        script.src = src;
    },

    // Show a file download
    downloadJSON ( json, filename = 'data.json' ) {

        // Download
        const blob = new Blob (
            [ JSON.stringify ( json, null, '\t' ) ],
            {
                type: 'application/json;charset=utf-8'
            }
        );
        saveAs ( blob, filename );

    },
    downloadDataFile ( content, mimeType, fileName ) {

        const blob = new Blob (
            [ content ],
            { type: `${ mimeType };charset=utf-8` }
        );
        saveAs ( blob, fileName );

    },

    // Split an array into chunks or batches
    chunkArray ( array = [], chunkSize ) {
        return array.length
            ? [
                array.slice ( 0, chunkSize ),
                ...this.chunkArray ( array.slice ( chunkSize ), chunkSize )
            ]
            : [];
    },

    // Read a file input file as JSON
    readJSONFileInput ( file ) {
        return new Promise ( ( resolve, reject ) => {

            const fileReader = new FileReader ();
            fileReader.onload = function ( file_e ) {
                try {
                    return resolve ( JSON.parse ( file_e.target.result ) );
                } catch ( e ) {
                    return reject ( new Error (
                        'File does not contain valid JSON.' ) );
                }
            };
            fileReader.readAsText ( file );

        } );
    },

    // Read a file input file as JSON
    readCSVFileInput ( file ) {
        return new Promise ( ( resolve, reject ) => {

            const fileReader = new FileReader ();
            fileReader.onload = function ( file_e ) {
                try {

                    PapaCSV.parse ( file_e.target.result, {
                        header        : true,
                        dynamicTyping : true,
                        skipEmptyLines: true,

                        // Use callbacks because we're using worker threads
                        worker  : true,
                        complete: results => {
                            return resolve ( results );
                        },
                        error   : e => {
                            this.logger ( 'warn', 'CSV Parse error', e );
                            return reject ( new Error (
                                'File does not contain valid CSV.' ) );
                        }
                    } );

                } catch ( e ) {
                    return reject ( new Error (
                        'File does not contain valid CSV.' ) );
                }
            };
            fileReader.readAsText ( file );

        } );
    },

    // Save a JSON blob to local storage
    setJSONLocalStorage ( key, blob ) {
        window.localStorage[ key ] = JSON.stringify ( blob );
    },

    // Get a JSON blob from localstorge
    getJSONLocalStorage ( key ) {
        try {
            return JSON.parse ( window.localStorage[ key ] );
        } catch ( e ) {
            return null;
        }
    },

    // Delete an item from localstorge
    deleteLocalStorage ( key ) {
        try {
            return JSON.parse ( window.localStorage[ key ] );
        } catch ( e ) {
            return null;
        }
    },

    // Parse AJV validation errors
    parseJSONSchemaErrors ( schema, errors ) {

        if ( !Array.isArray ( errors ) ) {
            throw new Error ( 'Errors is not an array, cannot parse schema errors.' );
        }

        return errors.map ( error => {

            let e = {
                keyword: error.keyword
            };

            // Helper for retrieving the JSON schema property description
            const _getSchemaProperty = path => _.get (
                schema,
                path.replace ( '#/', '' )
                    .replace ( /\//g, '.' )
            );

            // Helper for getting custom validation error
            const _getCustomErrorMessage = ( field, defaultMessage ) => {
                const msg = _getSchemaProperty ( `properties.${ field }.validationError` );
                return ( msg || '' ).length === 0 ? defaultMessage : msg;
            };

            switch ( error.keyword ) {

                case 'required': {

                    e.fields = [ error.params.missingProperty ];
                    e.message = `Required field ${ _.startCase ( e.fields[ 0 ] ) } is missing.`;

                    break;

                }

                case 'enum': {

                    let field = error.dataPath.substr ( 1 )
                        .replace ( /\./g, '.properties.' );
                    let allowed = _getSchemaProperty ( `properties.${ field }.enum` )
                        .map ( x => _.startCase ( x ) )
                        .join ( ', ' )
                    ;
                    e.fields = [ field ];
                    e.message = _getCustomErrorMessage (
                        field.replace ( /\.properties\./g, '.' ),
                        `${ _.startCase ( e.fields[ 0 ] ) } should be one of the following values: ${ allowed }`
                    );

                    break;

                }

                case 'pattern': {

                    e.fields = [ error.dataPath.substr ( 1 ) ];
                    e.message = _getCustomErrorMessage (
                        e.fields[ 0 ],
                        `${ _.startCase ( e.fields[ 0 ] ) } ${ error.message }`
                    );

                    break;

                }

                default: {

                    e.fields = [ error.dataPath.substr ( 1 ) ];
                    e.message = _getCustomErrorMessage (
                        e.fields[ 0 ],
                        `${ _.startCase ( e.fields[ 0 ] ) } ${ error.message }`
                    );

                }

            }

            return e;

        } );

    },

    // A promise-based sleep function
    wait: ( s ) => new Promise ( r => setTimeout ( r, s * 1000 ) ),

    // Clear all browser cookies
    clearCookies () {
        var cookies = document.cookie.split ( '; ' );
        for ( var c = 0; c < cookies.length; c++ ) {
            var d = window.location.hostname.split ( '.' );
            while ( d.length > 0 ) {
                var cookieBase = encodeURIComponent ( cookies[ c ].split ( ';' )[ 0 ].split (
                    '=' )[ 0 ] ) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join (
                    '.' ) + ' ;path=';
                var p = location.pathname.split ( '/' );
                document.cookie = cookieBase + '/';
                while ( p.length > 0 ) {
                    document.cookie = cookieBase + p.join ( '/' );
                    p.pop ();
                }
                d.shift ();
            }
        }
    },

    countCookies () {
        return document.cookie.split ( '; ' ).length;
    },

    // Copy text to clipboard
    copyTextToClipboard ( text ) {

        let textArea = document.createElement ( 'textarea' );

        //
        // *** This styling is an extra step which is likely not required. ***
        //
        // Why is it here? To ensure:
        // 1. the element is able to have focus and selection.
        // 2. if element was to flash render it has minimal visual impact.
        // 3. less flakyness with selection and copying which **might** occur if
        //    the textarea element is not visible.
        //
        // The likelihood is the element won't even render, not even a
        // flash, so some of these are just precautions. However in
        // Internet Explorer the element is visible whilst the popup
        // box asking the user for permission for the web page to
        // copy to the clipboard.
        //

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';

        textArea.value = text;

        document.body.appendChild ( textArea );
        textArea.focus ();
        textArea.select ();

        let success;
        try {
            success = document.execCommand ( 'copy' );
            success = true;
        } catch ( e ) {
            success = false;
            this.logger ( 'error', 'Error copying to clipboard: ' + e.message );
        }

        document.body.removeChild ( textArea );
        return success;

    },

    // Parse validation error response into form-friendly validation object
    parseFormValidationErrors ( errors = [] ) {

        const validationErrors = {};
        errors.forEach ( e => {
            validationErrors[ e.fields[ 0 ] ] = e.message;
        } );
        return validationErrors;

    }

};

// The plugin
const UtilsPlugin = {

    // Plugin installation
    install ( Vue ) {

        Object.defineProperty ( Vue.prototype, '$utils', {
            value: Utils
        } );

        // Top-level error handler (catches anything uncaught)
        window.onerror = function ( message, source, line, column, error ) {

            if ( !error ) {
                error = new Error ( message );
            }

            let vm = Vue.prototype;
            let err = vm.$utils.normaliseError ( error );
            err.component = 'GLOBAL';
            err.source = source;
            err.line = line;
            err.column = column;

            vm.$utils.logger ( 'error', '[UNCAUGHT ERROR]', err );
            //vm.$modal.showError ( err, vm );

            return false;

        };

        // Application-level global error handler
        // For uncaught errors during component render function and watchers.
        Vue.config.errorHandler = ( e, vm, info ) => {

            try {

                let err = vm.$utils.normaliseError ( e );
                err.component = vm.$utils.formatComponentName (
                    vm,
                    true
                );
                err.info = info;

                vm.$utils.logger ( 'error', '[GLOBAL ERROR]', err );
                //vm.$modal.showError ( err, vm );

            } catch ( localError ) {
                console.error ( 'This is slightly embarrassing - an error occurred in the error handler.', {
                    error        : localError,
                    originalError: e,
                    vm,
                    info
                } );
            }

        };

    }

};

Vue.use ( UtilsPlugin );