/**
 *  User store
 */
import Vue           from 'vue';
import { config }    from '../../config/spa';
import { get, omit } from 'lodash-es';

export default {

    namespaced: true,

    // Store state
    state: {

        isLoggedIn: false,
        user      : null,
        token     : null

    },

    // Generate getters
    getters: {

        isLoggedIn: state => state.isLoggedIn,
        token     : state => !!state.token,
        isAdmin   : state => !!get ( state, 'user.admin' ),
        profile   : state => state.user

    },

    // Setters
    mutations: {

        // Auth - Success
        authSuccess ( state, data ) {

            state.token = data.token;
            state.user = data.user;
            state.isLoggedIn = true;

            // Store the token in local storage
            Vue.prototype.$utils.setJSONLocalStorage ( 'auth.token', data.token );
            Vue.prototype.$utils.setJSONLocalStorage ( 'auth.user', data.user );

        },

        // Auth - Error
        authError ( state ) {
            state.token = null;
            state.isLoggedIn = false;
            localStorage.removeItem ( 'auth.token' );
            localStorage.removeItem ( 'auth.user' );
        },

        // Auth - Logout Success
        logoutSuccess ( state ) {

            state.token = null;
            state.isLoggedIn = false;
            state.user = null;

            // Remove the auth info
            localStorage.removeItem ( 'auth.token' );
            localStorage.removeItem ( 'auth.user' );

        },

        // Update user
        updateUser ( state, user ) {

            state.user = user;
            Vue.prototype.$utils.setJSONLocalStorage ( 'auth.user', user );

        }

    },

    // Actions
    actions: {

        // Auth - login
        async login ( context, user ) {

            try {

                let response = await this._vm.$httpAnon.post (
                    `${ config.apiRoot }/auth/login`,
                    user,
                    {
                        responseType: 'json'
                    }
                );

                // Set the auth header for all requests
                Vue.prototype.$http.defaults.headers.common[ 'Authorization' ] = `JWT ${ response.data.token.jwt }`;

                // Update store state
                context.commit ( 'authSuccess', response.data );

                // Emit login event
                Vue.prototype.$eventBus.$emit ( 'auth-login', context );

                return response;

            } catch ( e ) {

                context.commit ( 'authError' );

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        },

        // Auth - logout
        async logout ( context ) {

            // Clear the auth header for all requests
            delete Vue.prototype.$http.defaults.headers.common[ 'Authorization' ];

            // Update store state
            context.commit ( 'logoutSuccess' );

            // Emit logout event
            Vue.prototype.$eventBus.$emit ( 'auth-logout' );

        },

        // Check local storage for a logged in user
        async checkLoginState ( context ) {

            // In case we've initialised
            if ( context.state.isLoggedIn ) {
                return true;
            }

            // Check local storage for a persisted auth state
            const user = this._vm.$utils.getJSONLocalStorage ( 'auth.user' );
            const token = this._vm.$utils.getJSONLocalStorage ( 'auth.token' );
            if ( token && user ) {

                // Set store state
                context.state.user = user;
                context.state.token = token;
                context.state.isLoggedIn = true;

                // Set the auth header for all requests
                Vue.prototype.$http.defaults.headers.common[ 'Authorization' ] = `JWT ${ token.jwt }`;

                return true;

            }

            return false;

        },

        // Save user changes
        async save ( context, user ) {

            try {

                let response;

                if ( user.id ) {
                    response = await this._vm.$http.put (
                        `${ config.apiRoot }/user/${ user.id }`,
                        omit ( user, [ 'id' ] ),
                        {
                            responseType   : 'json',
                            withCredentials: false // CORS dev server
                        }
                    );
                } else {
                    response = await this._vm.$http.post (
                        `${ config.apiRoot }/users`,
                        user,
                        {
                            responseType   : 'json',
                            withCredentials: false // CORS dev server
                        }
                    );
                }

                // Update store state
                if ( user.id === context.state.user.id ) {
                    context.commit ( 'updateUser', response.data );
                }

                return response;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        },

        // Delete a user
        async delete ( context, userId ) {

            if ( userId === context.state.user.id ) {
                throw new Error ( 'Cannot delete own user account.' );
            }

            try {

                let response;

                response = await this._vm.$http.delete (
                    `${ config.apiRoot }/user/${ userId }`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                return response;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        }

    }

};