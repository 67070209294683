/**
 *  Payment import store
 */
import Vue        from 'vue';
import { config } from '../../config/spa';
import { omit }   from 'lodash-es';

export default {

    namespaced: true,

    // Store state
    state: {},

    // Generate getters
    getters: {},

    // Setters
    mutations: {},

    // Actions
    actions: {

        // Save changes
        async save ( context, record ) {

            try {

                let response;

                if ( record.id ) {
                    response = await this._vm.$http.put (
                        `${ config.apiRoot }/payment-import/${ record.id }`,
                        omit ( record, [ 'id' ] ),
                        {
                            responseType   : 'json',
                            withCredentials: false // CORS dev server
                        }
                    );
                } else {
                    response = await this._vm.$http.post (
                        `${ config.apiRoot }/payment-imports`,
                        record,
                        {
                            responseType   : 'json',
                            withCredentials: false // CORS dev server
                        }
                    );
                }

                return response;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        },

        // Delete a record
        async delete ( context, id ) {

            try {

                let response;

                response = await this._vm.$http.delete (
                    `${ config.apiRoot }/payment-import/${ id }`,
                    {
                        responseType   : 'json',
                        withCredentials: false // CORS dev server
                    }
                );

                return response;

            } catch ( e ) {

                // Bubble the exception
                throw Vue.prototype.$utils.normaliseError ( e );

            }

        }

    }

};