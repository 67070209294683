<template>
	<b-navbar bottom variant="light" type="light" class="p-0 border-top">
		<b-nav-text class="text-center w-100 p-2">&copy; Copyright 2020 Clive Elliott</b-nav-text>
	</b-navbar>
</template>

<script>
export default {
    name: 'TemplateFooter'
};
</script>
<style scoped lang="scss"></style>