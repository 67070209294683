import Vue from 'vue';

Vue.directive ( 'focus', {
    inserted: ( el, binding ) => {

        // Focus element
        el.focus ();

        // Process args
        switch ( binding.arg ) {

            case 'select':
                el.select();
                break;

        }

    }
} );