import Vue       from 'vue';
import VueRouter from 'vue-router';
import store     from '@/store';

Vue.use ( VueRouter );

const routes = [
    {
        path       : '/',
        name       : 'login',
        component  : () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        beforeEnter: async ( to, from, next ) => {

            const loggedIn = await store.dispatch ( 'user/checkLoginState' );

            if ( loggedIn ) {
                Vue.prototype.$utils.logger ( 'info', '[AUTH] Logged in, redirecting to dashboard..' );
                return next ( { name: 'dashboard' } );
            }

            next ();

        }
    },
    {
        path     : '/dashboard',
        name     : 'dashboard',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
    },
    {
        path     : '/users',
        name     : 'users',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "users" */ '../views/admin/Users.vue')
    },
    {
        path     : '/locations',
        name     : 'locations',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "locations" */ '../views/Locations.vue')
    },
    {
        path     : '/classes',
        name     : 'classes',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "classes" */ '../views/Classes.vue')
    },
    {
        path     : '/transaction-types',
        name     : 'transactionTypes',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "transactionTypes" */ '../views/TransactionTypes.vue')
    },
    {
        path     : '/payment-methods',
        name     : 'paymentMethods',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "paymentMethods" */ '../views/PaymentMethods.vue')
    },
    {
        path     : '/students',
        name     : 'students',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "students" */ '../views/Students.vue')
    },
    {
        path     : '/payments',
        name     : 'payments',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "payments" */ '../views/Payments.vue')
    },
    {
        path     : '/payment-imports',
        name     : 'paymentImports',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "paymentImports" */ '../views/PaymentImports.vue')
    },
    {
        path     : '/reports/student/profile',
        name     : 'studentProfileReport',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "studentProfileReport" */ '../views/reports/StudentProfile.vue')
    },
    {
        path     : '/reports/payment/reconciliation',
        name     : 'paymentReconciliationReport',
        meta     : { requiresAuth: true },
        component: () => import(/* webpackChunkName: "paymentReconciliationReport" */ '../views/reports/PaymentReconciliation.vue')
    },
    {
        path       : '*',
        beforeEnter: ( to, from, next ) => {
            next ( '/dashboard' );
        }
    }
];

const router = new VueRouter (
    {
        routes
    }
);

router.beforeEach ( async ( to, from, next ) => {

    // Protect auth routes
    if ( to.meta.requiresAuth ) {

        const loggedIn = await store.dispatch ( 'user/checkLoginState' );
        if ( !loggedIn ) {
            Vue.prototype.$utils.logger ( 'warn', '[AUTH] Not logged in, redirecting to login..' );
            return next ( { name: 'login' } );
        }

    }

    next ();

} );

export default router;
