import Vue from 'vue';

// The plugin
const EventBusPlugin = {

    // Plugin installation
    install ( Vue ) {

        // Create an eventbus for handling events between components
        Object.defineProperty (
            Vue.prototype,
            '$eventBus',
            { value: new Vue () }
        );

    }

};

Vue.use ( EventBusPlugin );